import React, { useEffect, getCompanies } from 'react';
import { Tabs } from 'antd';

import CompanyProvider, { useCompanies } from './state/companies';
import DatabaseProvider, { useDatabases } from './state/databases';
import UserProvider, { useUsers } from './state/users';

import Companies from './companies';
import Users from './users';
import Databases from './databases';

const Admin: React.FC<any> = () =>
{
   const { companies, getCompanies } = useCompanies();
   const { databases, getDatabases } = useDatabases();
   const { users, getUsers } = useUsers();

   useEffect(() =>
   {
      getCompanies();
      getDatabases();
      getUsers();
   }, []);

   const tabs = [
      {
         key: 1,
         label: 'Companies',
         children: <Companies databases={databases} />
      },
      {
         key: 2,
         label: 'Users',
         children: <Users companies={companies} />
      },
      {
         key: 3,
         label: 'Databases',
         children: <Databases databases={databases} />
      }
   ];

   return <Tabs className="mt-4" type="card" centered items={tabs} />
};

const AdminPage: React.FC<any> = () =>
{
   return (
      <DatabaseProvider>
         <CompanyProvider>
            <UserProvider>
               <Admin />
            </UserProvider>
         </CompanyProvider>
      </DatabaseProvider>
   );
};

export default AdminPage;
