import React from 'react';

import AppView from "./app";
import TableView from './table';
import ChartView from './chart';
import Suggestions from './suggest';
import type Message from '../types/message';

import { useChat } from '../state/chat';

const UserPage: React.FC<any> = () =>
{
   const {chat, displayMessageId, reportSuggestions} = useChat();

   let display, sql;

   if (reportSuggestions)
   {
      display = <Suggestions suggestions={reportSuggestions} />
   }

   if (displayMessageId)
   {
      const message = chat.find( (m: Message) => m.openai_message_id === displayMessageId );

      if (message)
      {
         if (message.sql)
         {
            sql = (
               <pre className="text-sm bg-black text-white whitespace-pre-wrap">
                  {message.sql}
               </pre>
            );
         }

         if (message.format === "table")
         {
            display = <TableView message={message} />
         }
         else if (message.format === "chart")
         {
            display = <ChartView message={message} />
         }
      }
   }

   return (
      <div className='flex-1 overflow-auto flex justify-between'>
         <div className='overflow-auto flex flex-col m-4'>
            {display}{sql}
         </div>
         <AppView />
      </div>
   );
};

export default UserPage;
