import React, { useRef, useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { BsFillMicFill, BsSend, BsArrowDownCircleFill } from 'react-icons/bs';
import { Input } from 'antd';

import { useChat } from '../../state/chat';
import Header from './header';
import MessageView from './message';
import Message from '../../types/message';

const AppView: React.FC<any> = () =>
{
   const [text, setText] = useState<string>('');
   const [showScrollToButton, setShowScrollToButton] = useState<boolean>(false);
   const [animationLoading, setAnimationLoading] = useState<boolean>(true);
   const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
      useSpeechRecognition();

   const {
      chat, sendMessage, isBusy, isBusier,
      displayMessageId, setDisplayMessageId
   } = useChat();

   const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
   };

   const messagesEndRef = useRef<HTMLDivElement>(null);
   const buttonRef = useRef<HTMLDivElement>(null);

   const handleChatBoxScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = buttonRef.current!;
      if (scrollHeight * 0.9 < scrollTop + clientHeight) {
         setShowScrollToButton(false);
      } else {
         setShowScrollToButton(true);
      }
   };

   useEffect(() =>
   {
      setAnimationLoading(false);
      scrollToBottom();
   }, [chat]);

   useEffect(() => {
      setText(transcript);
   }, [transcript]);

   const sendTextf = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      sendText();
   }

   const sendText = async () => {
      resetTranscript();
      sendMessage({ text, created_at: Date.now() });
      setText('');
   };

   const microphoneFunc = () => {
      if (listening) {
         SpeechRecognition.stopListening();
      } else {
         SpeechRecognition.startListening();
      }
   };

   if (!browserSupportsSpeechRecognition) {
      return <span>Browser doesn't support speech recognition.</span>;
   }

   const onChangeFunc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(e.target.value);
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
   };

   const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && e.altKey) {
         e.preventDefault();
         setText((e.target as HTMLButtonElement).value + '\n');
      } else if (e.key === 'Enter') {
         e.preventDefault();
         sendText();
      }
   };

   const sortedChat = [...chat];
   sortedChat.sort((ma, mb) => ma.created_at - mb.created_at);
   const messages = sortedChat.map( (message: Message, i: number) =>
      <MessageView
         key={i}
         message={message}
         displayed={displayMessageId === message.openai_message_id}
         setDisplayed={setDisplayMessageId}
      />
   );

   if (isBusy && isBusier)
   {
      messages.push(
         <MessageView
            key={'busy'}
            message={{
               format: "busy",
               text: "Give me a minute to pull up the information.",
               role: 'assistant',
               created_at: Date.now()
            }}
            displayed={false}
            setDisplayed={null}
         />
      );
   }

   return (
      <div
         className={`
            bg-neutral-200 relative m-4 rounded-3xl w-1/3
            flex-none flex flex-col overflow-y-auto
            ${animationLoading ? 'my-rotate-y-270' : 'my-rotate-y-360'}
            preserve-3d backface-hidden
         `}
      >
         <Header />
         <div
            id='scroll-id'
            ref={buttonRef}
            onScroll={handleChatBoxScroll}
            className='h-full px-3 py-2 md:px-6 md:py-4 gap-4 overflow-y-auto overflow-x-hidden flex flex-col relative'
         >
            {messages}
            {isBusy && (
               <div
                  className={`mr-auto rounded-xl py-2 px-3 lg:max-w-4xl max-w-xs md:max-w-lg text-slate-800 bg-white -mb-3 lg:-ml-1 lg:-mb-6 mt-auto`}
               >
                  <span className='break-words text-xs sm:text-sm flex'>
                     <div className='loader-dots block relative w-16 h-3 mt-1'>
                        <div className='absolute top-0 w-2 h-2 rounded-full bg-slate-600'></div>
                        <div className='absolute top-0 w-2 h-2 rounded-full bg-slate-600'></div>
                        <div className='absolute top-0 w-2 h-2 rounded-full bg-slate-600'></div>
                        <div className='absolute top-0 w-2 h-2 rounded-full bg-slate-600'></div>
                     </div>
                  </span>
               </div>
            )}
            <div ref={messagesEndRef} />
         </div>
         <form
            onSubmit={sendTextf}
            className='py-2 px-3 flex justify-between items-center bg-neutral-100 rounded-b-3xl shadow-md shadow-slate-800'
         >
            <Input.TextArea
               autoSize={{ minRows: 2, maxRows: 6 }}
               onKeyDown={handleKeyDown}
               disabled={isBusy}
               onChange={(e) => onChangeFunc(e)}
               value={text}
               placeholder='How can I help you..'
               className='rounded-xl mr-4 md:ml-4 ml-1 overflow-hidden'
            />
            <div className='flex gap-2 md:gap-3 md:px-2'>
               <button
                  type='reset'
                  disabled={isBusy}
                  onClick={() => microphoneFunc()}
                  className={`w-10 h-10 ${!listening && 'hover:scale-105'} ${
                     listening && 'animate-pulse scale-110'
                  } bg-slate-800 cursor-pointer transition-transform rounded-full flex justify-center items-center`}
               >
                  <BsFillMicFill className={`w-5 h-5 text-neutral-200  `} />
               </button>
               <button
                  disabled={isBusy}
                  type='submit'
                  className='w-10 h-10 bg-slate-800 hover:scale-105 cursor-pointer group transition-transform rounded-full flex justify-center items-center'
               >
                  <BsSend className='w-5 h-5 text-neutral-200 ' />
               </button>
            </div>
         </form>
         <div
            onClick={() => scrollToBottom()}
            className={`${!showScrollToButton && 'hidden'} ${
               !animationLoading && 'hidden'
            } absolute bottom-20 right-8 z-50 bg-neutral-200 transition-transform 
         cursor-pointer animate-bounce flex justify-center items-center rounded-full`}
         >
            <BsArrowDownCircleFill className='text-slate-800 w-12 h-12' />
         </div>
      </div>
   )
}

export default AppView;
