import React, { useState } from 'react';
import { Table, Modal, Input, Form, Button, Select } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useCompanies } from "./state/companies";

const Companies: React.FC<any> = ({ databases }: any) =>
{
   const [form] = Form.useForm();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isUpdateModal, setIsUpdateModal] = useState(false);

   const { companies, addCompany, updateCompany, deleteCompany, getCompanies } = useCompanies();

   const handleConfirm = async (values) =>
   {
      if (isUpdateModal)
      {
         if (await updateCompany(values))
            await getCompanies();
      }
      else
      {
         if (await addCompany(values))
            await getCompanies();
      }

      setIsModalOpen(false);
   }

   const cancel = () =>
   {
      setIsModalOpen(false);
   }

   const handleFail = () =>
   {
   }

   const dataSource = companies.map( (c, i) =>
   {
      const db = databases.find( d => d.id === c.database_id );
      return {
         id: c.id,
         name: c.name,
         database_id: c.database_id,
         key: i,
         assistant_id: c.assistant_id
      };
   });

   const columns = [
      {
         title: "Id",
         dataIndex: "id"
      },
      {
         title: "Name",
         dataIndex: "name",
      },
      {
         title: "Database",
         render: (_, record) =>
         {
            const database = databases.find( d =>
               d.id === record.database_id
            );
            if (database) return <span>{database.name}</span>;
            return <span>...</span>;
         },
         key: "database_id"
      },
      {
         title: "Assistant",
         dataIndex: "assistant_id",
         key: "assistant_id"
      },
      {
         title: "Actions",
         render: (_, record) => (
            <>
               <button
                  className="ml-1 mr-2"
                  onClick={() =>
                  {
                     form.setFieldsValue({
                        id: record.id,
                        name: record.name,
                        database_id: record.database_id,
                        assistant_id: record.assistant_id
                     });
                     setIsUpdateModal(true);
                     setIsModalOpen(true);
                  }}
               >
                  <EditOutlined />
               </button>
               <button
                  onClick={() => {
                     deleteCompany(record.id);
                  }}
               >
                  <DeleteOutlined />
               </button>
            </>
         )
      },
   ];

   return (
      <>
         <div className="block m-4">
            <span className="text-2xl">Companies</span>
            <span className="text-lg ml-3">
               <button
                  onClick={() => {
                     form.resetFields();
                     form.setFieldsValue({
                        database_id: databases[0].id
                     });
                     setIsUpdateModal(false);
                     setIsModalOpen(true);
                  }}
               >
                  <PlusCircleOutlined />
               </button>
            </span>
         </div>
         <Table dataSource={dataSource} columns={columns} />
         <Modal
            title={isUpdateModal ? "Update Company" : "Add Company"}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={cancel}
            footer={
               <Button form="updateCompany" key="submit" htmlType="submit">
                  Submit
               </Button>
            }
         >
            <Form
               id="updateCompany"
               form={form}
               onFinish={handleConfirm}
               onFinishFailed={handleFail}
               autoComplete="off"
            >
               <Form.Item
                  label="Id"
                  name="id"
                  hidden={true}
               />
               <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                     {
                        required: true,
                        message: "Name is required"
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Database"
                  name="database_id"
               >
                  <Select options={ databases.map( d => ({
                     value: d.id,
                     label: d.name
                  })) } />
               </Form.Item>
               <Form.Item
                  label="Assistant ID"
                  name="assistant_id"
                  rules={[
                     {
                        required: true,
                        message: "Assistant ID is required"
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
            </Form>
         </Modal>
      </>
   );
}

export default Companies;
