import React, { useState } from 'react';
import { Table, Modal, Input, Form, Button, Select } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { useDatabases } from "./state/databases";

const Databases: React.FC<any> = () =>
{
   const [form] = Form.useForm();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isUpdateModal, setIsUpdateModal] = useState(false);

   const { databases, addDatabase, updateDatabase, deleteDatabase, getDatabases } = useDatabases();

   const handleConfirm = async (values) =>
   {
      if (isUpdateModal)
      {
         if (await updateDatabase(values))
            await getDatabases();
      }
      else
      {
         if (await addDatabase(values))
            await getDatabases();
      }

      setIsModalOpen(false);
   }

   const cancel = () =>
   {
      setIsModalOpen(false);
   }

   const handleFail = () =>
   {
   }

   const dataSource = databases.map( (c, i) => ({ ...c, key: i }) );

   const columns = [
      {
         title: "Id",
         dataIndex: "id"
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name"
      },
      {
         title: "Product",
         dataIndex: "product",
         key: "product"
      },
      {
         title: "Host",
         dataIndex: "host",
         key: "host"
      },
      {
         title: "Database",
         dataIndex: "database",
         key: "database"
      },
      {
         title: "User",
         dataIndex: "database_user",
         key: "user"
      },
      {
         title: "Password",
         dataIndex: "password",
         key: "password"
      },
      {
         title: "Actions",
         render: (_, record) => (
            <>
               <button
                  className="ml-1 mr-2"
                  onClick={() =>
                  {
                     form.setFieldsValue({
                        id: record.id,
                        name: record.name,
                        product: record.product,
                        host: record.host,
                        password: record.password,
                        database: record.database,
                        user: record.user
                     });
                     setIsUpdateModal(true);
                     setIsModalOpen(true);
                  }}
               >
                  <EditOutlined />
               </button>
               <button
                  onClick={() => {
                     deleteDatabase(record.id);
                  }}
               >
                  <DeleteOutlined />
               </button>
            </>
         )
      }
   ];

   return (
      <>
         <div className="block m-4">
            <span className="text-2xl">Databases</span>
            <span className="text-lg ml-3">
               <button
                  onClick={() => {
                     form.resetFields();
                     form.setFieldsValue({
                        product: "mysql"
                     });
                     setIsUpdateModal(false);
                     setIsModalOpen(true);
                  }}
               >
                  <PlusCircleOutlined />
               </button>
            </span>
         </div>
         <Table dataSource={dataSource} columns={columns} />
         <Modal
            title={isUpdateModal ? "Update Database" : "Add Database"}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={cancel}
            footer={
               <Button form="updateDatabase" key="submit" htmlType="submit">
                  Submit
               </Button>
            }
         >
            <Form
               id="updateDatabase"
               form={form}
               onFinish={handleConfirm}
               onFinishFailed={handleFail}
               autoComplete="off"
            >
               <Form.Item
                  label="Id"
                  name="id"
                  hidden={true}
               />
               <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                     {
                        required: true,
                        message: "Name is required"
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Product"
                  name="product"
               >
                  <Select options={[
                     {
                        value: 'mysql',
                        label: 'mySQL'
                     },
                     {
                        value: 'mssql',
                        label: 'SQL Server'
                     }
                  ]} />
               </Form.Item>
               <Form.Item
                  label="Host"
                  name="host"
                  rules={[
                     {
                        required: true,
                        message: "Host is required"
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Database"
                  name="database"
                  rules={[
                     {
                        required: true,
                        message: "Database is required"
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="User"
                  name="database_user"
                  rules={[
                     {
                        required: true,
                        message: "User is required"
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                     {
                        required: true,
                        message: "Password is required"
                     }
                  ]}
               >
                  <Input.Password />
               </Form.Item>
            </Form>
         </Modal>
      </>
   );
}

export default Databases;
