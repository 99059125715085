import React, { useState } from 'react';
import { Table, Modal, Input, Form, Button, Select } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useUsers } from "./state/users"

const Users: React.FC<any> = ({ companies }: any) =>
{
   const [form] = Form.useForm();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isUpdateModal, setIsUpdateModal] = useState(false);

   const { users, addUser, updateUser, deleteUser, getUsers } = useUsers();

   const handleConfirm = async (values) =>
   {
      if (isUpdateModal)
      {
         if (await updateUser(values))
            await getUsers();
      }
      else
      {
         if (await addUser(values))
            await getUsers();
      }

      setIsModalOpen(false);
   }

   const cancel = () =>
   {
      setIsModalOpen(false);
   }

   const handleFail = () =>
   {
   }

   const dataSource = users
      .map( (u, i) => ({ ...u, key: i }))
      .sort( (a, b) => a.id - b.id );

   const columns = [
      {
         title: "Id",
         dataIndex: "id"
      },
      {
         title: "Name",
         dataIndex: "name",
      },
      {
         title: "Email",
         dataIndex: "email",
      },
      {
         title: "Company",
         render: (_, record) => {
            const company = companies.find( c =>
               c.id === record.company_id
            );
            if (company) return <span>{company.name}</span>;
            return <span>...</span>;
         },
         key: "company_id"
      },
      {
         title: "Role",
         dataIndex: "role",
      },
      {
         title: "Business Role",
         dataIndex: "business_role",
      },
      {
         title: "Actions",
         render: (_, record) => (
            <>
               <button
                  className="ml-1 mr-2"
                  onClick={() =>
                  {
                     form.setFieldsValue({
                        id: record.id,
                        name: record.name,
                        email: record.email,
                        company_id: record.company_id,
                        password: '',
                        role: record.role,
                        business_role: record.business_role
                     });
                     setIsUpdateModal(true);
                     setIsModalOpen(true);
                  }}
               >
                  <EditOutlined />
               </button>
               <button
                  onClick={() => {
                     deleteUser(record.id);
                  }}
               >
                  <DeleteOutlined />
               </button>
            </>
         )
      }
   ];

   return (
      <>
         <div className="block m-4">
            <span className="text-2xl">Users</span>
            <span className="text-lg ml-3">
               <button
                  onClick={() => {
                     form.resetFields();
                     form.setFieldsValue({
                        company_id: companies[0].id,
                        role: "user"
                     });
                     setIsUpdateModal(false);
                     setIsModalOpen(true);
                  }}
               >
                  <PlusCircleOutlined />
               </button>
            </span>
         </div>
         <Table dataSource={dataSource} columns={columns} />
         <Modal
            title={isUpdateModal ? "Update User" : "Add User"}
            open={isModalOpen}
            onOk={handleConfirm}
            onCancel={cancel}
            footer={
               <Button form="updateUser" key="submit" htmlType="submit">
                  Submit
               </Button>
            }
         >
            <Form
               id="updateUser"
               form={form}
               onFinish={handleConfirm}
               onFinishFailed={handleFail}
               autoComplete="off"
            >
               <Form.Item
                  label="Id"
                  name="id"
                  hidden={true}
               />
               <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                     {
                        required: true,
                        message: "Name is required"
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                     {
                        required: true,
                        message: "Email is required"
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                     {
                        required: !isUpdateModal,
                        message: "Password is required"
                     }
                  ]}
               >
                  <Input.Password />
               </Form.Item>
               <Form.Item
                  label="Company"
                  name="company_id"
               >
                  <Select options={ companies.map( c => ({
                     value: c.id,
                     label: c.name
                  })) } />
               </Form.Item>
               <Form.Item
                  label="Role"
                  name="role"
                  rules={[
                     {
                        required: true,
                        message: "Role is required"
                     }
                  ]}
               >
                  <Select options={[
                     {
                        value: 'admin',
                        label: 'Admin'
                     },
                     {
                        value: 'owner',
                        label: 'Owner'
                     },
                     {
                        value: 'user',
                        label: 'Company User'
                     }
                  ]} />
               </Form.Item>
               <Form.Item
                  label="Business Role"
                  name="business_role"
               >
                  <Input />
               </Form.Item>
            </Form>
         </Modal>
      </>
   );
}

export default Users;
