import React, { useState } from "react";
import 'regenerator-runtime/runtime';
import { useAuth } from "./state/auth";
import Gpt from './gpt/index';
import "./App.css";
import { Button, Form, Input, Space, Typography } from 'antd';

function App() {
   const [form] = Form.useForm();
   const [ invalid, setInvalid ] = useState(false);
   const { user, logIn } = useAuth();

   const handleLogin = async (values: any) =>
   {
      const result = await logIn(values.email, values.password);
      if (!result) setInvalid(true);
   };

   if (user)
   {
     return (
       <div className="App">
         <Gpt />
       </div>
     );
   }
   else
   {
      return (
         <>
            <center>
               <img className="mt-20 mb-14" src="enzoDB-logo.png" alt="Enzo DB" width="300px" />
            </center>
            <Form
               labelCol={{ span: 8 }}
               wrapperCol={{ span: 8 }}
               form={form}
               onFinish={handleLogin}
            >
               <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                  <Input />
               </Form.Item>
               <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                  <Input.Password />
               </Form.Item>
               <Form.Item
                  wrapperCol={{ offset: 8, span: 16 }}
               >
                  <Space>
                     <Button type="primary" htmlType="submit">
                        Submit
                     </Button>
                  </Space>
               </Form.Item>
               {invalid &&
                  <Form.Item
                     wrapperCol={{ offset: 8 }}
                  >
                     <Typography.Text type="danger">
                        Email or password incorrect.
                     </Typography.Text>
                  </Form.Item>
               }
            </Form>
         </>
      );
   }
}

export default App;
