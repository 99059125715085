import React, { useContext, createContext, useEffect, useState } from "react";
const AuthContext = createContext<any>(null);

const AuthProvider = ({ children }: any) =>
{
   const [user, setUser] = useState();
   const [showAdminPage, setShowAdminPage] = useState(false);

   const logIn = async (email: string, password: string) =>
   {
      const response = await fetch("/api/user/login", {
        method: "POST",
        headers: {
           'Content-Type': "application/json",
        },
        body: JSON.stringify({ email, password })
      });

      if (response.ok)
      {
        setUser(await response.json());
        return true;
      }

      return false;
   }

   const logOut = async () =>
   {
      const response = await fetch("api/user/logout");

      if (response.ok)
      {
         setShowAdminPage(false);
         setUser(undefined);
      }
   };

   useEffect(() =>
   {
     async function getUser()
     {
       const response = await fetch("/api/user/info");

       if (response.ok)
       {
         setUser(await response.json());
       }
     }

     getUser();
   }, []);

   return (
      <AuthContext.Provider value={{
         user, setUser, logOut, logIn,
         showAdminPage, setShowAdminPage
      }}>
         {children}
      </AuthContext.Provider>
   );
}

export default AuthProvider;

export const useAuth = (): any =>
{
   return useContext(AuthContext);
}
