import React, { useEffect, useState, useContext, createContext } from "react";
import Database from '../../types/database';

const DatabaseContext = createContext<any>(null);

const DatabaseProvider = ({ children }: any) =>
{
   const [databases, setDatabases] = useState<Database[]>([]);

   const getDatabases = async () =>
   {
      const response = await fetch(`api/database`);

      if (response.ok)
      {
         setDatabases(await response.json());
      }
   }

   const addDatabase = async (database: Database) =>
   {
      const response = await fetch(`api/database/create`, {
         method: "POST",
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(database)
      });

      return response.ok;
   }

   const updateDatabase = async (database: Database) =>
   {
      const response = await fetch(`api/database/update`, {
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(database)
      });

      return response.ok;
   }

   const deleteDatabase = async (id: number) =>
   {
      const response = await fetch(`api/database/delete/${id}`);

      await getDatabases();
   }

   return (
      <DatabaseContext.Provider value={{ databases, addDatabase, deleteDatabase, getDatabases, updateDatabase }}>
         {children}
      </DatabaseContext.Provider>
   );
}

export default DatabaseProvider;

export const useDatabases = () =>
{
   return useContext(DatabaseContext);
}
