import React, { useEffect, useState, useContext, createContext } from "react";
import { useAuth } from './auth';
import { useChat } from './chat';
import Thread from '../types/thread';

const ThreadContext = createContext<any>(null);

const ThreadProvider = ({ children }: any) =>
{
   const [threads, setThreads] = useState<Thread[]>([]);
   const { user } = useAuth();
   const { refreshChat, thread } = useChat();

   useEffect(() =>
   {
      if (user?.id) getThreads(user.id, setThreads);
   },
   [user, thread]);

   const addThread = async () =>
   {
      const response = await fetch(`api/thread/`, {
         method: "POST",
         headers: {
            'Content-Type': 'application/json',
         },
         body: undefined
      });

      if (response.ok)
      {
         const newThread = await response.json();
         setThreads([...threads, newThread]);

         refreshChat();
      }
   }

   const setThreadName = async (id: number, name: string) =>
   {
      const response = await fetch(`api/thread/edit/${id}`, {
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({ name })
      });

      if (response.ok)
      {
         const newThreads = [...threads];
         const thread = newThreads.find( t => t.id === id );
         if (thread) thread.name = name;

         setThreads(newThreads);
      }
   }

   const deleteThread = async (thread: Thread) =>
   {
      const response = await fetch(`api/thread/delete/${thread.openai_thread_id}`);

      if (response.ok)
      {
         await getThreads(user.id, setThreads);
         refreshChat();
      }
   }

   return (
      <ThreadContext.Provider value={{ threads, addThread, deleteThread, setThreadName }}>
         {children}
      </ThreadContext.Provider>
   );
}

async function getThreads(id: number, setThreads: any)
{
   const response = await fetch(`api/thread/user/${id}`);

   if (response.ok)
   {
      setThreads(await response.json());
   }
}

export default ThreadProvider;

export const useThreads = () =>
{
   return useContext(ThreadContext);
}
