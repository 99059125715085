import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthProvider from './state/auth';
import ChatProvider from './state/chat';
import ThreadProvider from './state/thread';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
   document.getElementById('root') as HTMLElement
);
root.render(
   <AuthProvider>
      <ChatProvider>
         <ThreadProvider>
            <App />
         </ThreadProvider>
      </ChatProvider>
   </AuthProvider>
);
