import React from 'react';
import Header from "./header";
import UserPage from './user';
import AdminPage from './admin';

import { useAuth } from '../state/auth'

const Gpt: React.FC<any> = () =>
{
   const {showAdminPage} = useAuth();

   return (
      <div className='flex h-screen flex-col'>
         <Header />
         {showAdminPage ?
            <AdminPage />
         :
            <UserPage />
         }
      </div>
   );
};

export default Gpt;
