import * as React from 'react';
import { Table, Button, Select } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

export default function TableView({ message }: { message: any })
{
   const text = JSON.parse(message.text);
   const allColumns = text.columns.map( (c: string) => (
   {
      key: c,
      dataIndex: c,
      title: c,
      sorter: (a:any, b:any) => sort(a[c], b[c])
   }));

   const rows: any = [];
   text.data.forEach( (d: any, i: number) =>
   {
      const row: any = { key: i };
      text.columns.forEach( (c: string, j: number) => row[c] = findColumn(c, j, d) );
      rows.push(row);
   });

   const defaultShownColumns = allColumns.map( c => ({ value: c.dataIndex, label: c.title }));
   const [ shownColumns, setShownColumns ] = React.useState(defaultShownColumns);

   const onChange = (_, changed) =>
   {
      setShownColumns(changed);
   };

   const columns = allColumns.filter(
      c => shownColumns.find(
         s => s.value === c.dataIndex
      )
   );

   const csvData = [columns.map( s => s.title )];
   rows.forEach( r =>
   {
      const row = [];
      columns.forEach( c =>
      {
         row.push( r[c.dataIndex] );
      });
      csvData.push(row);
   });

   return (
      <div>
         <div className="flex">
            <Button className="mb-2" icon={<FileExcelOutlined />}>
               <CSVLink filename={`${text.title || "table"}.csv`} data={csvData}>Export</CSVLink>
            </Button>
            <Select
               className="mb-2 ml-2"
               mode="multiple"
               onChange={onChange}
               style={{width: '100%'}}
               options={defaultShownColumns}
               maxTagCount="responsive"
               defaultValue={allColumns.map( c => c.dataIndex )}
            />
         </div>
         <Table dataSource={rows} columns={columns} size="small" />
      </div>
   );
}

function sort(a: any, b: any): number
{
   if (typeof a === "string")
   {
      return a.localeCompare(b);
   }

   return a - b;
}

function findColumn(name: string, index: number, dataRow: any)
{
   if (Array.isArray(dataRow)) return dataRow[index];
   if (dataRow[name]) return dataRow[name];

   const keys = Object.keys(dataRow);

   const _name = keys.find( key => {
      const k = key.replaceAll(' ', '_').toLowerCase();
      const n = name.replaceAll(' ', '_').toLowerCase();
      if (k === n) return true;

      const krev = k.split('_').reverse().join('_');
      return krev === n;
   });
   if (_name) return dataRow[_name];

   return null;
}
