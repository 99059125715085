import React, { useState } from 'react';
import { useThreads } from '../../state/thread';
import { useChat } from '../../state/chat';
import { SaveOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';

const Header: React.FC<any> = () =>
{
   const [ editing, setEditing ] = useState(false);
   const { deleteThread, addThread, setThreadName } = useThreads();
   const { isBusy, thread, setThread } = useChat();
   const [ value, setValue ] = useState(thread.name);

   let title;

   const handleInputSubmit = (e:any) =>
   {
      e.preventDefault();
      setThread({...thread, name: value });
      setThreadName(thread.id, value);
      setEditing(false);
   };

   if (editing)
   {
      title = (
         <form onSubmit = {handleInputSubmit}>
            <Space.Compact>
               <Input value={value} onChange={(e) => setValue(e.target.value)} />
               <button type="submit">
                  <SaveOutlined className="ml-2" />
               </button>
            </Space.Compact>
         </form>
      );
   }
   else
   {
      title = (
         <div>
            <span className='font-bold'>{thread?.name ?? 'New Thread'}</span>
            <button
               onClick={() => setEditing(true)}
            >
               <EditOutlined className="ml-2" />
            </button>
         </div>
      );
   }

   return (
      <div className='flex justify-between py-4 px-6 shadow-sm shadow-slate-300 rounded-t-3xl'>
         <button
            disabled={isBusy}
            onClick={addThread}
         >
            <PlusCircleOutlined />
         </button>
         {title}
         <button
            disabled={isBusy}
            onClick={() => deleteThread(thread)}
         >
            <DeleteOutlined />
         </button>
      </div>
   );
}

export default Header;
