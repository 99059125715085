import React from 'react';
import { Button } from 'antd';
import { TableOutlined } from '@ant-design/icons';
import { useChat } from '../state/chat';

function Suggestions({ suggestions }: { suggestions: string[] })
{
   const { sendMessage } = useChat();

   function sendReport(report: string)
   {
      return function()
      {
         sendMessage({
            text: `Show me a report for "${report}"`,
            created_at: Date.now()
         });
      }
   }

   return (
      <div>
         <p>Here are some reports you might be interested in:</p>
         <div
            className="grid grid-cols-2 m-5"
         >
         {
            suggestions.map( (s: string, index) => 
               <div
                  key = {index}
                  className = "m-2"
               >
                  <Button
                     onClick={sendReport(s)}
                  >
                     <TableOutlined />
                     {s}
                  </Button>
               </div>
            )
         }
         </div>
      </div>
   );
}

export default Suggestions;
