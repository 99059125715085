import React from 'react';
import { Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, SoundOutlined, MutedOutlined } from '@ant-design/icons';
import { useAuth } from "../state/auth";
import { useThreads } from "../state/thread";
import { useChat } from "../state/chat";
import Thread from "../types/thread";

export default function Header()
{
   const { user, logOut, showAdminPage, setShowAdminPage } = useAuth();
   const { threads } = useThreads();
   const { thread, refreshChat, userWantsMusic, toggleMusic } = useChat();

   const items = threads.map( (thread: Thread) => (
   {
      key: thread.id,
      label: thread.name || 'New Thread'
   }));

   const onClick: MenuProps['onClick'] = ({ key }) =>
   {
      refreshChat(key);
   }

   let adminButton = null;

   if (user.role === "admin")
   {
      let text = "Admin";
      if (showAdminPage) text = "App";
      adminButton = <button onClick={() => setShowAdminPage(!showAdminPage)} className="hover:text-gray-800">{text}</button>;
   }

   return (
      <header className="bg-white shadow-md rounded-t-md">
         <nav className="flex justify-between items-center px-4 py-3">
            <div className='hover:scale-125 transition-transform'>
               <a rel='noopener noreferrer' target='_blank' href='https://harplabs.com'>
                  <img src="enzoDB-logo.png" className='h-8' alt='' />
               </a>
            </div>
            <div className="flex">
               <p className="text-2xl font-bold text-gray-800">
                  <span className="block text-indigo-500">{user.name}</span>
               </p>
            </div>
            <div className="flex text-sm text-gray-600 space-x-4">
               <button
                  onClick={() => toggleMusic()}
               >
                  {userWantsMusic ? <SoundOutlined /> : <MutedOutlined />}
               </button>
               {adminButton}
               {threads.length > 0 &&
                  <Dropdown menu={{items, onClick}}>
                     <Space>
                        <div>
                           {thread?.name ?? "Threads"}
                        </div>
                        <DownOutlined />
                     </Space>
                  </Dropdown>
               }
               <button onClick={logOut} className="hover:text-gray-800">Logout</button>
            </div>
         </nav>
      </header>
   );
}
