import React from 'react';
import type Message from '../../types/message';
import { TableOutlined, BarChartOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Markdown from 'react-markdown';

function MessageView({message, displayed, setDisplayed} : {message: Message, displayed:boolean, setDisplayed: any})
{
   function textClass()
   {
      switch (message.role)
      {
         case 'user':
         case 'error': return 'text-white';
         default:      return 'text-slate-800';
      }
   }

   function displayClass()
   {
      if (message.format === "table")
      {
         return displayed ?
            `bg-blue-400 mr-[36px]` :
            `bg-white mr-[36px]`;
      }

      if (message.format === "chart")
      {
         return displayed ?
            `bg-green-400 mr-[36px]` :
            `bg-white mr-[36px]`;
      }

      if (message.format === "busy")
      {
         return `bg-yellow-400 mr-[36px]`;
      }

      const t = textClass();

      switch (message.role)
      {
         case 'user':  return `${t} bg-slate-800 ml-[36px]`;
         case 'error': return `${t} bg-red-600 mr-[36px]`;
         default:      if (message.format === "question")
                            return `${t} bg-yellow-200 mr-[36px]`;
                       else return `${t} bg-white mr-[36px]`;
      }
   }

   function toggleView()
   {
      if (displayed) setDisplayed(null);
      else           setDisplayed(message.openai_message_id);
   }

   let messageBox;
   if (message.format === "table")
   {
      const json = JSON.parse(message.text);
      messageBox = (
         <Button
            onClick={toggleView}
         >
            <TableOutlined />
            {json.title}
         </Button>
      );
   }
   else if (message.format === "chart")
   {
      const json = JSON.parse(message.text);
      messageBox = (
         <Button
            onClick={toggleView}
         >
            <BarChartOutlined />
            {json.title}
         </Button>
      );
   }
   else
   {
      messageBox = (
         <div className='break-words text-xs sm:text-sm'>
            <Markdown>{message.text.replace(/\\n/g, '\n')}</Markdown>
         </div>
      );
   }

   return (
      <div
         className={`flex ${message.text === '' && 'hidden'} ${
            message.role === 'user' ? 'ml-auto' : 'mr-auto'
         } gap-2 text-slate-800`}
      >
         <div
            className={`mr-auto rounded-xl pt-2 pb-1 px-3 lg:max-w-xl max-w-sm md:max-w-md sm:max-w-md ${displayClass()} flex flex-col`}
         >
            {messageBox}
            <span
               className={`text-[8px] sm:text-[10px] ml-auto -mb-[3px] mt-1 ${textClass()} opacity-40`}
            >
               {new Date(Number(message.created_at)).toLocaleTimeString()}
            </span>
         </div>
      </div>
   );
}

export default MessageView;
