import React, { useEffect, useState, useContext, createContext } from "react";
import Company from '../../types/company';

const CompanyContext = createContext<any>(null);

const CompanyProvider = ({ children }: any) =>
{
   const [companies, setCompanies] = useState<Company[]>([]);

   const getCompanies = async () =>
   {
      const response = await fetch(`api/company`);

      if (response.ok)
      {
         setCompanies(await response.json());
      }
   }

   const addCompany = async (company: Company) =>
   {
      const response = await fetch(`api/company/create`, {
         method: "POST",
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(company)
      });

      return response.ok;
   }

   const updateCompany = async (company: Company) =>
   {
      const response = await fetch(`api/company/update`, {
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(company)
      });

      return response.ok;
   }

   const deleteCompany = async (id: number) =>
   {
      const response = await fetch(`api/company/delete/${id}`);

      await getCompanies();
   }

   return (
      <CompanyContext.Provider value={{ companies, addCompany, deleteCompany, getCompanies, updateCompany }}>
         {children}
      </CompanyContext.Provider>
   );
}

export default CompanyProvider;

export const useCompanies = () =>
{
   return useContext(CompanyContext);
}
