import React, { useEffect, useState, useContext, createContext } from "react";
import User from '../../types/user';

const UserContext = createContext<any>(null);

const UserProvider = ({ children }: any) =>
{
   const [users, setUsers] = useState<User[]>([]);

   const getUsers = async () =>
   {
      const response = await fetch(`api/user`);

      if (response.ok)
      {
         setUsers(await response.json());
      }
   }

   const addUser = async (user: User) =>
   {
      const response = await fetch(`api/user/create`, {
         method: "POST",
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(user)
      });

      return response.ok;
   }

   const updateUser = async (user: User) =>
   {
      const response = await fetch(`api/user/update`, {
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(user)
      });

      return response.ok;
   }

   const deleteUser = async (id: number) =>
   {
      const response = await fetch(`api/user/delete/${id}`);

      await getUsers();
   }

   return (
      <UserContext.Provider value={{ users, addUser, deleteUser, getUsers, updateUser }}>
         {children}
      </UserContext.Provider>
   );
}

export default UserProvider;

export const useUsers = () =>
{
   return useContext(UserContext);
}
