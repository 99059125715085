import React from 'react';

import { Bar, Pie } from 'react-chartjs-2';
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';
Chart.register(CategoryScale);

function ChartView({ message }: { message: any})
{
   const chart = JSON.parse(message.text);

   let chartData: any = {};

   if (Array.isArray(chart.data))
   {
      chartData.labels =  chart.data.map( (d: any) => d.label );

      chartData.datasets = [
      {
         label: chart.title || "Value",
         data: chart.data.map( (d: any) => d.value )
      }];
   }
   else
   {
      chartData.labels = chart.data.labels;

      if (chart.data.datasets)
      {
         chartData.datasets = chart.data.datasets;
      }
      else
      {
         chartData.datasets = [{
            label: chart.title || 'Value',
            data: chart.data.values
         }];
      }
   }

   if (chart.type === "pie")
   {
      return <Pie data={chartData} />
   }
   else
   {
      return <Bar data={chartData} />
   }
}

export default ChartView;
