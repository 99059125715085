import React, { useRef, useState, useEffect, useContext, createContext } from "react";
import io from 'socket.io-client';
import Message from '../types/message';
import { useAuth } from './auth';

const USER_PATIENCE_MS = 10000;
const ChatContext = createContext<any>(null);

const songs = [
   'background-music-instrumental-207886.mp3',
   'bossa-nova-jazz-podcast-music-207165.mp3',
   'brazilian-bossa-nova-jazz-acoustic-guitar-podcast-music-201763.mp3',
   'new-york-skyline-203813.mp3'
];

const ChatProvider = ({ children }: any) =>
{
   const [chat, setChat] = useState<Message[]>([]);
   const [displayMessageId, setDisplayMessageId] = useState<string>();
   const [reportSuggestions, setReportSuggestions] = useState<string[]>();
   const [thread, setThread] = useState<any>({ name: '' });
   const [socket, setSocket] = useState<any>(null);
   const [isBusy, setIsBusy] = useState<boolean>(true);
   const [isBusier, setIsBusier] = useState<boolean>(false);
   const [isBusiest, setIsBusiest] = useState<boolean>(false);
   const [userWantsMusic, setUserWantsMusic] = useState<boolean>(true);

   const busyRef = useRef(true);

   const { user } = useAuth();

   const song = songs[Math.floor(Math.random() * 4)];
   const audio = new Audio(song);
   audio.loop = true;
   audio.addEventListener('pause', event =>
   {
      if (isBusiest)
      {
         const ding = new Audio('ding-36029.mp3');
         ding.play();
      }
   });

   const audioRef = useRef(audio);

   useEffect(() =>
   {
      if (user)
      {
         setSocket(io('/socket'));
         setIsBusy(false);
         setIsBusiest(false);
         busyRef.current = false;
      }
      else
      {
         setSocket(null);
      }
   },
   [user]);

   useEffect(() =>
   {
      if (socket)
      {
         socket.emit('get-suggestions');
      }
   },
   [socket]);

   useEffect(() =>
   {
      if (socket)
      {
         socket.removeAllListeners('thread');
         socket.on('thread', async (thread: any) =>
         {
            setThread(thread);
            setChat(thread.messages);
            setIsBusy(false);
            setIsBusiest(false);
            busyRef.current = false;
         });

         socket.removeAllListeners('message');
         socket.on('message', async (message: Message) =>
         {
            setChat([...chat, message]);
            setDisplayMessageId(message.openai_message_id);
            setIsBusy(false);
            setIsBusiest(false);
            audioRef.current.pause();
            busyRef.current = false;
         });

         socket.removeAllListeners('updateThreadName')
         socket.on('updateThreadName', async (name: string) =>
         {
            setThread({ ...thread, name });
         });

         socket.removeAllListeners('error');
         socket.on('error', async (message: { error: string }) =>
         {
            setChat([...chat, {
               text: message.error,
               role: 'error',
               format: "error",
               created_at: Date.now()
            }]);
            setIsBusy(false);
            setIsBusiest(false);
            busyRef.current = false;
            audioRef.current.pause();
         });

         socket.removeAllListeners('report-suggestions');
         socket.on('report-suggestions', async (message: Message) =>
         {
            setReportSuggestions(JSON.parse(message.text));
         });

         socket.removeAllListeners('disconnect');
         socket.on('disconnect', async () =>
         {
            setIsBusy(false);
            setIsBusiest(false);
            busyRef.current = false;
            audioRef.current.pause();

            setChat([...chat, {
               text: "Lost contact with server",
               role: 'error',
               format: "error",
               created_at: Date.now()
            }]);
         });
      }
   },
   [socket, chat, thread]);

   const refreshChat = (thread_id?: number) =>
   {
      socket.emit('refresh', { thread_id });
      busyRef.current = true;
      setIsBusy(true);
   }

   const playMusic = () =>
   {
      if (busyRef.current)
      {
         setIsBusiest(true);
         audioRef.current.play();
      }
   }

   const toggleMusic = () =>
   {
      if (isBusiest)
      {
         if (userWantsMusic)
         {
            audioRef.current.pause();
         }
         else
         {
            audioRef.current.play();
         }
      }

      setUserWantsMusic(!userWantsMusic);
   }

   const showBePatientMessage = () =>
   {
      if (busyRef.current)
      {
         setIsBusier(true);
         setTimeout(() => playMusic(), USER_PATIENCE_MS);
      }
   }

   const sendMessage = (message: { text: string, created_at: number }) =>
   {
      socket.emit('newMessage', message);
      setChat([...chat, {
         text: message.text,
         role: "user",
         format: "chat",
         created_at: message.created_at
      }]);
      busyRef.current = true;
      setIsBusier(false);
      setIsBusy(true);
      setTimeout(() => showBePatientMessage(), USER_PATIENCE_MS);
   }

   return (
      <ChatContext.Provider
         value={{
            chat, thread, setThread, displayMessageId,
            setDisplayMessageId, reportSuggestions,
            sendMessage, isBusy, isBusier, refreshChat,
            toggleMusic, userWantsMusic
         }}>
         {children}
      </ChatContext.Provider>
   );
}

export default ChatProvider;

export const useChat = () =>
{
   return useContext(ChatContext);
}
